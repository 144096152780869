import React from 'react';

import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';

import { DynamicImage, Text } from 'components';
import ContinueButton from '../QuizContinueButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  padding: 3rem 0;
  @media ${tablet} {
    padding: 1.5rem 0 100px 0;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  max-width: 1140px;
  @media ${tablet} {
    flex-direction: column;
    gap: 1.5rem;
  }
`;
const BottomTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  width: 100%;
`;

const ImageContainer = styled.div`
  width: 50%;
  @media ${tablet} {
    min-height: 150px;
    max-height: 350px;
    width: 100%;
  }
`;
const StyledDynamicImage = styled(DynamicImage)`
  @media ${tablet} {
    max-height: 250px;
    width: 100%;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
  span {
    font-weight: 600;
  }
  i {
    font-weight: 300;
  }
  @media ${tablet} {
    width: 100%;
  }
`;
const TextImgWrapper = styled.div``;

const StyledDynamicTextImage = styled(DynamicImage)`
  width: 50px;
`;
const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.light100};
`;

const StyledText = styled(Text)`
  letter-spacing: -0.384px;
  line-height: 140%;
  i {
    letter-spacing: -0.432px;
  }
`;

type TextAndImgColumnProps = {
  onContinue: () => void;
  image?: string;
  image_mob?: string;
  alt?: string;
  title?: string;
  description?: string;
  nextBtnTitle?: string;
};

const TextAndImgColumn = ({
  image,
  image_mob,
  alt,
  title,
  description,
  nextBtnTitle,
  onContinue,
}: TextAndImgColumnProps) => {
  const { isTablet } = useQuery();

  return (
    <Container>
      <Content>
        {(image || image_mob) && (
          <ImageContainer>
            <StyledDynamicImage
              src={isTablet ? image_mob || image : image}
              alt={alt || 'info'}
            />
          </ImageContainer>
        )}

        <TextContainer>
          {title && (
            <StyledText
              type={isTablet ? 'bodyM' : 'body'}
              color="dark80"
              dangerouslySetInnerHTML={{ __html: title || '' }}
            />
          )}

          {description && (
            <StyledText
              type={isTablet ? 'bodyM' : 'h4400'}
              color="dark100"
              dangerouslySetInnerHTML={{ __html: description || '' }}
            />
          )}
        </TextContainer>
      </Content>
      <ContinueButton title={nextBtnTitle} onClick={onContinue} />
    </Container>
  );
};

export default TextAndImgColumn;
