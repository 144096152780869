import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from 'components';
import { tablet } from 'styles/breakpoints';

const Button = styled(PrimaryButton)`
  width: 26.25rem;
  margin: 3rem auto;
  @media ${tablet} {
    width: 100%;
    margin: 1.56rem 0;
  }
`;

const Wrapper = styled.div`
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem;
    width: 100%;
    background-color: white;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: ${({ theme }) => theme.colors.light100};
    button {
      margin: 0;
    }
  }
`;

const ContinueButton = (props: ComponentProps<typeof PrimaryButton>) => (
  <Wrapper>
    <Button {...props}>{props?.title ?? 'Continue'}</Button>
  </Wrapper>
);

export default ContinueButton;
